<template>
  <draggable
    v-if="sortedImages.length"
    v-model="sortedImages"
    item-key="uuid"
    :animation="200"
    class="row ma-1 w100"
    @end="onDragEnd"
  >
    <v-col
      v-for="image in sortedImages"
      :key="image.uuid"
      cols="12"
      :md="colSize"
      style="position: relative"
    >
      <VIcon
        v-if="canDelete"
        class="icon-top-right"
        color="error"
        @click="removeFile(image)"
      >
        mdi-close-circle
      </VIcon>

      <VRow
        class="dropbox"
        justify="center"
        align="center"
        no-gutters
        style="position: relative"
      >
        <VCol justify="center" align="center">
          <img
            :src="image[urlKey] ?? image[urlDefaultKey]"
            class="image-preview"
            alt="Dresskare Media"
          />
          <slot name="imgBottom" :image="image" />
        </VCol>
      </VRow>
    </v-col>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "DresskareBaseImageDragAndDrop",
  components: {
    draggable,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    colSize: {
      type: Number,
      default: 4,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    urlKey: {
      type: String,
      default: "image",
    },
    urlDefaultKey: {
      type: String,
      default: "image",
    },
  },
  data() {
    return {
      sortedImages: [...this.images].sort((a, b) => a.order - b.order),
    };
  },
  computed: {
    marginIfCanDelete() {
      return { "mt-2": this.canDelete };
    },
  },
  watch: {
    images: {
      deep: true,
      handler(newImages) {
        // This is to avoid reloading images when the order is in progress to be change an image after an other
        if (newImages.length === this.sortedImages.length) {
          return;
        }
        this.sortedImages = [...newImages].sort((a, b) => a.order - b.order);
      },
    },
  },
  methods: {
    onDragEnd() {
      this.sortedImages.forEach((img, index) => {
        img.order = index;
      });
      this.$emit("update:images", [...this.sortedImages]);
    },
    removeFile(image) {
      this.$emit("delete", image);
    },
  },
};
</script>

<style scoped>
.v-card {
  cursor: grab;
}
.drag-handle {
  cursor: grab;
}
.icon-top-right {
  position: absolute !important;
  right: 0px;
  top: 0px;
  z-index: 100;
}
.dropbox {
  border: 1px dashed black;
  min-height: 200px; /* minimum height */
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
.image-preview {
  max-width: 100%;
  max-height: 197px;
}
</style>
