<template>
  <VNavigationDrawer
    :value="value"
    class="sidebar__drawer"
    :mini-variant="miniVariant"
    v-bind="$attrs"
    app
    @update:mini-variant="$emit('update:mini-variant', $event)"
    @input="$emit('input', $event)"
  >
    <VList dense class="mt-1">
      <VListItem
        class="d-flex justify-start mb-2"
        :ripple="false"
        @click="$emit('update:mini-variant', !miniVariant)"
      >
        <VListItemIcon class="mr-4">
          <VIcon color="primary"> mdi-menu </VIcon>
        </VListItemIcon>
        <VListItemTitle
          class="text--primary--theme font-weight-black text-uppercase subtitle-1"
        >
          Dresskare
        </VListItemTitle>
      </VListItem>
      <DresskareNavListItems :items="displayedItems" @click="goToPage" />
    </VList>

    <template #append>
      <slot name="append-before-divider"></slot>
      <VDivider />
      <VRow v-if="!miniVariant" class="" no-gutters>
        <h4 class="px-4 py-4">{{ $t("account") }}</h4>
      </VRow>

      <DresskareNavListItems :items="appendNavItems" @click="goToPage" />

      <VList dense class="">
        <VListItem
          class="d-flex justify-center"
          :ripple="false"
          @click="contactSupport"
        >
          <VListItemAvatar>
            <VIcon color="primary" size="28"> mdi-face-agent </VIcon>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="text--primary--theme title-font">
              {{ $t("contactSupport") }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>

        <VListItem
          class="d-flex justify-center"
          :ripple="false"
          @click="signOutOidc"
        >
          <VListItemAvatar>
            <VIcon color="primary" size="28"> mdi-exit-to-app </VIcon>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="text--primary--theme title-font">
              {{ $t("logout") }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>

        <VRow class="mt-4 ml-4" no-gutters> </VRow>

        <VListItem
          id="item-profile"
          class="my-4 d-flex justify-center"
          :ripple="false"
          @click="goToProfile"
        >
          <VListItemAvatar>
            <DresskareBaseInitials
              :first-name="currentUser.firstName"
              :last-name="currentUser.lastName"
              :profil-picture="currentUser.profilPicture"
              size="28"
              font-size="11px"
              :color="avatarColor"
            />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle
              class="text--primary--theme"
              style="font-size: 10px"
            >
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </VListItemTitle>
            <VListItemSubtitle style="font-size: 10px">
              {{ $t("myProfil") }}
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </VList>
      <VRow class="mt-0 mb-2 ml-4 text-center" no-gutters justify="center">
        <VCol>
          <a
            class="text-caption"
            href="https://www.dresskare.com/conditions-generales-solution-plateforme-seconde-main"
            target="_blank"
          >
            {{
              miniVariant ? $t("generalConditionMini") : $t("generalCondition")
            }}
          </a>
        </VCol>
      </VRow>
    </template>
  </VNavigationDrawer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DresskareSideBar",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    navItems: {
      type: Array,
      default: () => [],
    },
    appendNavItems: {
      type: Array,
      default: () => [],
    },
    currentUser: {
      type: Object,
      required: true,
    },
    editProfileUrl: {
      type: [String, Object],
      required: true,
    },
    avatarColor: {
      type: String,
      default: "primary",
    },
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  computed: {
    displayedItems() {
      return this.navItems.filter((item) => {
        return item.displayLink;
      });
    },
  },
  methods: {
    ...mapActions("oidc", ["signOutOidc"]),
    contactSupport() {
      window.open("mailto:nadege@dresskare.com");
    },
    goToProfile() {
      if (this.$route.name !== this.editProfileUrl.name) {
        this.$router.push(this.editProfileUrl);
      }
    },
    goToPage(item) {
      if (item.external) {
        window.open(item.url, item.newTab ? "_blank" : "_self");
        return;
      }
      if (this.$route.name !== item.routeName) {
        this.$router.push({ name: item.routeName });
      }
    },
  },
};
</script>

<style lang="scss">
.title-font {
  font-size: 15px !important;
}
.sidebar__layout {
  flex: none;
  width: 60px;
}
.sidebar__drawer {
  height: 100dvh !important;
  .v-list--nav {
    padding: 0;
  }

  .v-list-group {
    .v-list-group__header {
      .v-list-group__header__append-icon {
        margin: 0 !important;
        min-width: unset !important;
      }
    }
  }

  .v-list {
    .v-list-item {
      padding: 5px 15px;
      height: 50px;
      &.v-list-item--active {
        background: $light-blue-1 !important;
      }
      &.v-list-item--link:before {
        background-color: $light-blue-1 !important;
      }
      &:hover {
        background: $light-blue-1 !important;
      }
      .v-list-item__icon {
        margin-right: 20px;
      }
    }
  }

  .white-append-icon {
    .v-list-group__header__append-icon {
      i {
        color: white !important;
      }
    }
  }

  &:not(.v-navigation-drawer--mini-variant) {
    #item-profile {
      border-radius: 20px;
      border: 1px solid $light-blue-2;
      padding: 5px 10px 5px 5px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &.v-navigation-drawer--mini-variant {
    #item-profile {
      padding: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "contactSupport": "Support",
    "logout": "Logout",
    "myProfil": "My profil",
    "account": "Account",
    "generalCondition": "General conditions",
    "generalConditionMini": "GC"
  },
  "fr": {
    "contactSupport": "Support",
    "logout": "Déconnexion",
    "myProfil": "Mon profil",
    "account": "Compte",
    "generalCondition": "Conditions générales",
    "generalConditionMini": "CGU"
  }
}
</i18n>
