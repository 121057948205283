<template>
  <VRow :justify="justify" :align="align" :class="marginIfNotBtn">
    <VCol v-if="label" cols="12" class="pb-0">
      <h4 class="mb-0 text--primary--theme">{{ label }} :</h4>
    </VCol>
    <template v-if="multiple && showMediaAsImage">
      <slot name="multipleImage">
        <VCol
          v-for="(image, index) in value"
          :key="image"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          style="position: relative"
          :class="marginIfCanDelete"
        >
          <VIcon
            v-if="image && canDelete && !readOnly"
            class="icon-top-right"
            color="error"
            @click="removeFile(index)"
          >
            mdi-close-circle
          </VIcon>
          <VRow class="dropbox" :justify="justify" :align="align" no-gutters>
            <VCol :justify="justify" :align="align">
              <img
                v-if="image"
                class="image-preview"
                :src="image"
                alt="Dresskare Media"
                @click="openFile(index)"
              />
            </VCol>
          </VRow>
        </VCol>
      </slot>
    </template>
    <VCol
      cols="12"
      :md="inputColsNumber"
      :class="viewerStyle"
      style="position: relative"
    >
      <VIcon
        v-if="!multiple && value && canDelete && !uploadInProgress && !readOnly"
        class="icon-top-right"
        color="error"
        @click="removeFile"
      >
        mdi-close-circle
      </VIcon>
      <VProgressCircular
        v-if="!multiple && value && uploadInProgress"
        size="20"
        indeterminate
        class="icon-top-right"
        color="primary"
      />
      <!-- <VBadge
        :value="!multiple && value && canDelete"
        color="error"
        icon="mdi-close-circle"
        overlap
        @click.native.stop="removeFile"
      > -->

      <VRow
        v-if="readOnly && !multiple"
        no-gutters
        :justify="justify"
        :align="align"
        class="dropbox"
      >
        <VCol>
          <img
            v-if="!multiple && value"
            class="image-preview"
            :src="value"
            alt="Dresskare Media"
          />
          <p v-else>{{ uploadText }}</p>
        </VCol>
      </VRow>
      <file-upload
        v-else-if="!readOnly || !multiple"
        ref="fileUpload"
        v-model="internalFiles"
        :input-id="inputId"
        :drop="true"
        :extensions="extensions"
        :size="size"
        :multiple="multiple"
        style="width: 100%"
        v-bind="$attrs"
        @input-file="inputFile"
      >
        <VRow v-if="showMediaAsAvatar" class="text-center">
          <VCol cols="12">
            <VAvatar size="150">
              <img
                v-if="!multiple && value"
                width="150px"
                :src="value"
                class="rounded-circle"
                alt="Dresskare Media"
                @click="openFile"
              />
              <VIcon v-else color="primary" size="150">
                mdi-account-circle
              </VIcon>
            </VAvatar>
          </VCol>
          <VCol cols="12">
            <VBtn color="primary" v-bind="btnAttrs">
              <VIcon v-if="btnIcon"> {{ btnIcon }} </VIcon>
              {{ uploadText }}
            </VBtn>
          </VCol>
        </VRow>
        <VRow
          v-else-if="showMediaAsImage && !$vuetify.breakpoint.mobile"
          no-gutters
          :justify="justify"
          :align="align"
          class="dropbox"
        >
          <VCol>
            <img
              v-if="!multiple && value"
              class="image-preview"
              :src="value"
              alt="Dresskare Media"
              @click="openFile"
            />
            <p v-else>
              {{ uploadInProgress ? uploadProgressText : uploadText }}
            </p>
          </VCol>
          <VCol v-if="uploadInProgress">
            <VProgressCircular indeterminate color="primary"
          /></VCol>
        </VRow>
        <VRow v-else no-gutters :justify="justify" :align="align">
          <VBtn color="primary" :loading="uploadInProgress" v-bind="btnAttrs">
            <span
              :style="`overflow-wrap: break-word; font-size: ${btnAttrs.fontSize};`"
            >
              <VIcon v-if="btnIcon" small> {{ btnIcon }} </VIcon>
              {{ uploadText }}
            </span>
          </VBtn>
        </VRow>
        <VRow v-if="extensions" no-gutters>
          <div class="text-caption">
            {{ $tc("supportedExtension", extensions.length) }}:
            <span v-for="extension in extensions" :key="extension"
              >{{ extension }}
            </span>
          </div>
        </VRow>
      </file-upload>
      <!-- </VBadge> -->
    </VCol>
    <VCol v-if="!showMediaAsImage && !onlyButton" cols="12" md="6">
      <p v-if="newFileName">{{ newFileName }}</p>
      <VBtn
        v-else-if="value"
        :color="$vuetify.theme.themes.light.dresskarered"
        dark
        @click="readOnly ? null : openFile(undefined, false)"
      >
        {{ seeText }}
      </VBtn>
    </VCol>
  </VRow>
</template>

<script>
import p from "image-blob-reduce";

const reduce = p();

export default {
  name: "DresskareBaseUpload",
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    showMediaAsImage: {
      type: Boolean,
      default: false,
    },
    showMediaAsAvatar: {
      type: Boolean,
      default: false,
    },
    seeText: {
      type: String,
      default: "See file",
    },
    uploadText: {
      type: String,
      default: "Click to upload",
    },
    uploadProgressText: {
      type: String,
      default: "Upload in progress",
    },
    label: {
      type: String,
      default: "",
    },
    btnAttrs: {
      type: Object,
      default: () => ({}),
    },
    btnIcon: {
      type: String,
      default: "",
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    extensions: {
      type: Array,
      default: undefined,
    },
    size: {
      type: Number,
      default: 0,
    },
    uploadInProgress: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    onlyButton: {
      type: Boolean,
      default: false,
    },
    mobileAutoSave: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    reduceMaxSize: {
      type: Number,
      default: 1080,
    },
    orderMap: {
      type: Object,
      default: () => {},
    },
    justify: {
      type: String,
      default: "center",
    },
    align: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      internalFiles: [],
      newFileName: "",
    };
  },
  mounted() {
    if (this.autoOpen) {
      document.getElementById(this.inputId).click();
    }
  },
  methods: {
    isImage(file) {
      return file.type.startsWith("image/");
    },
    async inputFile(newFile) {
      if (!newFile) {
        return;
      }
      this.newFileName = newFile.name;
      let fileToTransformToBase64 = newFile.file;
      if (this.isImage(fileToTransformToBase64)) {
        try {
          fileToTransformToBase64 = await reduce.toBlob(
            fileToTransformToBase64,
            {
              max: this.reduceMaxSize,
            }
          );
        } catch (err) {
          if (
            err.message.includes("getImageData") &&
            err.message.includes("fingerprinting protection")
          ) {
            // Ignore the error if it's related to fingerprinting protection.This happen on samsung internet device
            console.warn(
              "Fingerprinting protection is enabled, skipping getImageData."
            );
          } else {
            console.error(err);
          }
        }
      }
      const newValue = await this.toBase64(fileToTransformToBase64);
      // if(this.multiple) {
      //   this.$emit("input", [...this.value, newValue]);
      // } else {
      //   this.$emit("input", newValue);
      // }
      this.$emit("input", newValue);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    openFile(index, isImage = true) {
      // INFO - AM - 24/03/2024 - in mobile we do not want to open in a new tab to let the native functionnalities work
      if (this.$vuetify.breakpoint.mobile && isImage) {
        return;
      }
      // INFO - AM - 26/12/2023 - Index can be 0 !
      if (typeof index !== "undefined") {
        window.open(this.value[index], "_blank");
        return;
      }
      window.open(this.value, "_blank");
    },
    removeFile(index) {
      if (this.multiple) {
        this.$emit("delete", index);
      } else {
        this.internalFiles = [];
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style>
.dropbox {
  border: 1px dashed black;
  min-height: 200px; /* minimum height */
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
.image-preview {
  max-width: 100%;
  max-height: 197px;
}
.icon-top-right {
  position: absolute !important;
  right: -5px;
  top: -5px;
}
</style>


<i18n>
{
  "en": {
    "supportedExtension": "Accepted format | Accepted formats"
  },
  "fr": {
    "supportedExtension": "Format accepté | Formats acceptés"
  }
}
</i18n>
