<template>
  <div id="headway-container"></div>
</template>

<script>
export default {
  name: "HeadwayWidget",
  mounted() {
    const hwConfig = {
      selector: "#headway-container",
      account: process.env.VUE_APP_HEADWAY_ACCOUNT,
      translations: {
        title: this.$t("title"),
        readMore: this.$t("readMore"),
        labels: {
          new: this.$t("new"),
          improvement: this.$t("improvement"),
          fix: this.$t("fix"),
        },
        footer: this.$t("footer"),
      },
    };
    window.Headway.init(hwConfig);
  },
};
</script>

<style>

</style>

<i18n>
{
  "en": {
    "title": "The Changelog",
    "readMore": "Read more",
    "new": "News",
    "improvement": "Updates",
    "fix": "Fixes",
    "footer": "Read more 👉"
  },
  "fr": {
    "title": "Informations DressKare",
    "readMore": "Lire plus",
    "new": "Nouveautés",
    "improvement": "Améliorations",
    "fix": "Corrections",
    "footer": "Lire plus 👉"
  }
}
</i18n>
