import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { create } from "@bufbuild/protobuf";
import { Model } from "@vuex-orm/core";

import {
  ProductImageController,
  ProductImageRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
const productImageClient = useDresskareGrpcClient(ProductImageController);

export default class ProductImage extends Model {
  static entity = "productImage";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      product: this.attr(null),
      image: this.string(""),
      kind: this.number(0),
      visionApiData: this.attr(null),
      order: this.number().nullable(),
      image256: this.string(""),
      image32: this.string(""),
      imageWithoutBackground: this.string(""),
      backgroundRemoverModelUsed: this.string(null).nullable(),
    };
  }

  static getProductImages = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }
      const response = await productImageClient.list({}, { headers: metadata });

      let productImagesFromDB = response.results;

      if (!productImagesFromDB) {
        return null;
      }
      this.insertOrUpdate({ data: productImagesFromDB });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form, { save = true }) => {
    try {
      const response = await productImageClient.create(
        create(ProductImageRequestSchema, form),
        {}
      );

      let productImageFromDB = response;
      if (save) {
        this.insertOrUpdate({ data: productImageFromDB });
      }
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static updateInDb = async (form) => {
    const response = await productImageClient.update(
      create(ProductImageRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (productImageUuid) => {
    try {
      await productImageClient.destroy({ uuid: productImageUuid }, {});
      this.delete(productImageUuid);
    } catch (error) {
      console.error("error:", error);
    }
  };
}

export const MODEL_REMOVER = {
  U2NET: "u2net",
  BIREFNET_GENERAL_LITE: "birefnet-general-lite",
};
