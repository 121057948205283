import { Model } from "@vuex-orm/core";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { VendorConnectorController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/publisher_pb";
const vendorConnectorClient = useDresskareGrpcClient(VendorConnectorController);

// let annnouncementPromiseByUuid = {};

export default class VendorConnector extends Model {
  static entity = "vendorConnector";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      vendor: this.attr(null),
      isActive: this.boolean(true),
      publisherName: this.string(null),
      urn: this.string(null),
    };
  }

  static getVendorConnectors = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await vendorConnectorClient.list(undefined, {
        headers: metadata,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };
}
