<template>
  <div>
    <DresskareTextarea
      ref="suggestionText"
      :value="internalValue"
      v-bind="$attrs"
      outlined
      @input="analyseTextAndEmit"
    >
      <template #prepend>
        <VTooltip bottom>
          <template #activator="{ on, attrs }">
            <VIcon color="primary" dark v-bind="attrs" v-on="on">
              {{ prependIcon }}
            </VIcon>
          </template>
          <span>{{ textIcon }}</span>
        </VTooltip>
      </template>
    </DresskareTextarea>

    <VMenu v-bind="menuProps">
      <VList>
        <VListItem
          v-for="(item, index) in currentsFilteredItems"
          :key="index"
          @click="appendItemToText(item)"
        >
          <VListItemTitle>{{ getItemText(item) }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
    <!-- <DresskareCombobox
      v-model="lastMatchingWord"
      :items="itemsByTags[lastMatchingTag]"
      :menu-props="menuProps"
      v-bind="$attrs"
    /> -->
  </div>
</template>

<script>
export default {
  name: "DresskareBaseSuggestionInText",
  props: {
    value: {
      type: String,
      default: null,
    },
    itemsByTags: {
      type: Object,
      default: () => ({}),
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: "mdi-information-outline",
    },
    textIcon: {
      type: String,
      default: "Text to fill",
    },
    escapeFirstLetters: {
      type: Array,
      default: () => [],
    },
    lineHeight: {
      type: Number,
      default: 27,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      lastMatchingTag: "",
      lastMatchingWord: "",
      internalValue: "",
      menuProps: {
        value: false,
        absolute: true,
        "position-x": 50,
        "position-y": 50,
      },
    };
  },
  computed: {
    currentsFilteredItems() {
      if (!this.lastMatchingWord) {
        return this.itemsByTags[this.lastMatchingTag];
      }
      return this.itemsByTags[this.lastMatchingTag].filter((item) => {
        if (this.itemText) {
          item = item[this.itemText];
        }
        return item
          .toLowerCase()
          .startsWith(this.lastMatchingWord.toLowerCase());
      });
    },
  },
  watch: {
    value: {
      handler(newValue) {
        // INFO - AM - 06/04/2022 - If we are not using the same text that the value saved in database we change it to display the correct translation text
        if (
          this.itemText &&
          this.itemValue &&
          this.itemText !== this.itemValue
        ) {
          newValue = this.processValue(newValue, true);
        }
        this.internalValue = newValue;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$refs.suggestionText.$refs.input.style.lineHeight = `${this.lineHeight}px`;
    this.$refs.suggestionText.$refs.input.style.fontSize = `${this.fontSize}px`;
  },
  methods: {
    /*
     * Transform a word to it's value or this is text depend of the itemsByTags props.
     */
    processWord(word, toValue) {
      // INFO - AM - 06/04/2022 - if the first letter of the word is in the itemsByTags
      if (Object.keys(this.itemsByTags).includes(word[0])) {
        const wordWithoutTags = word.substr(1).trim();

        const item = this.itemsByTags[word[0]].find((item) => {
          // Using startwith instead of egual because punctuation can exist in the wordWithoutTags
          return toValue
            ? wordWithoutTags.startsWith(item.value)
            : wordWithoutTags.startsWith(item.text);
        });
        if (item) {
          let potentialPunctuation = wordWithoutTags.substr(
            toValue ? item.value.length : item.text.length
          );
          return `${word[0]}${
            toValue ? item.text : item.value
          }${potentialPunctuation}`;
        }
      }
      // INFO - AM - 23/04/2022 - for example #@colors or #dresskare_@colors
      let escapeWord = null;
      // HACK - AM - 22/01/2023 - With this algo the more complex escape should be at the end of the array. For example # and #dresskare_ both match #dresskare_@colors so #dresskare_ should be at the end of the array.
      this.escapeFirstLetters.forEach((escapeText) => {
        if (word.startsWith(escapeText)) {
          escapeWord = escapeText;
        }
      });
      if (escapeWord) {
        return `${word.substr(0, escapeWord.length)}${this.processWord(
          word.substr(escapeWord.length).trim(),
          toValue
        )}`;
      }
      return word;
    },
    processLine(line, toValue) {
      let words = line.split(" ");
      const wordsProcessed = words.map((word) =>
        this.processWord(word, toValue)
      );
      return wordsProcessed.join(" ");
    },
    processValue(newValue, toValue) {
      let lines = newValue.split("\n");
      const linesProcessed = lines.map((line) =>
        this.processLine(line, toValue)
      );
      return linesProcessed.join("\n");
    },
    getItemText(item) {
      if (typeof item === "object") {
        if (!this.itemText) {
          console.error(
            "If you use an object as item please use itemText prop"
          );
          return item;
        }
        return item[this.itemText];
      }
      return item;
    },
    getItemValue(item) {
      if (typeof item === "object") {
        if (!this.itemValue) {
          console.error(
            "If you use an object as item please use itemValue prop"
          );
          return item;
        }
        return item[this.itemValue];
      }
      return item;
    },
    appendItemToText(item) {
      const itemText = this.getItemText(item);
      const caretIndex = this.getCaretIndex();

      const lastMatchingTagIndex = caretIndex - this.lastMatchingWord.length;

      if (lastMatchingTagIndex === null || lastMatchingTagIndex < 0) {
        console.error("Last matching tag not found on text");
        return;
      }

      const before = this.internalValue.substr(0, lastMatchingTagIndex);
      const after = this.internalValue.substr(
        caretIndex,
        this.internalValue.length
      );

      this.internalValue = before + itemText + after;

      this.$nextTick().then(() => {
        const textarea = this.$refs.suggestionText.$refs.input;
        textarea.selectionStart = lastMatchingTagIndex + itemText.length;
        textarea.focus();
      });

      this.$emit("input", this.processValue(this.internalValue, false));
    },
    getCaretIndex() {
      const textarea = this.$refs.suggestionText.$refs.input;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }
      return pos;
    },
    getCaretPos() {
      // Getting the textarea element
      let textarea = this.$refs.suggestionText.$refs.input;

      const caretIndex = this.getCaretIndex();

      let lineSplitted = this.internalValue
        .substring(0, caretIndex - 1)
        .split("\n");

      let sizeInsideTextArea =
        (lineSplitted.length + 1) * this.lineHeight - textarea.scrollTop;

      // Appending element to the DOM after textarea
      textarea.insertAdjacentHTML(
        "afterend",
        `<span style="font-size: ${
          this.fontSize
        }px;" id='dummy'>${lineSplitted.at(-1)}</span>`
      );

      // Getting position info of the rectangles inside dummy element
      const dummy = document.getElementById("dummy");
      let rectangles = dummy.getClientRects();
      let last = rectangles[rectangles.length - 1];

      // Getting position info of the textarea
      let text = this.$refs.suggestionText.$el.getBoundingClientRect();

      // Setting coordinates
      // HACK - AM - 23/04/2022 - 1.5 is a magic number don't know why but working well like this
      let x = text.x + last.width * 1.5;
      let y = text.y + sizeInsideTextArea;

      // Removing dummy
      dummy.remove();

      // Returning variables
      return { x, y };
    },
    analyseTextAndEmit(text) {
      const caretIndex = this.getCaretIndex();

      const separationCharacter = [" ", "\n", "\t"];

      let firstLetterAfterSeparationCharacterIndex = 0;
      // INFO - AM - 06/04/2022 - We get the index of the next closer separation from the caret
      for (let i = caretIndex - 1; i >= 0; i--) {
        if (separationCharacter.includes(text[i])) {
          firstLetterAfterSeparationCharacterIndex = i + 1;
          break;
        }
      }
      let firstLetterOfCurrentCaretWord =
        text[firstLetterAfterSeparationCharacterIndex];

      // INFO - AM - 23/04/2022 - for example #@colors or #dresskare_@colors
      let escapeWord = null;
      // HACK - AM - 22/01/2023 - With this algo the more complex escape should be at the end of the array. For example # and #dresskare_ both match #dresskare_@colors so #dresskare_ should be at the end of the array.
      this.escapeFirstLetters.forEach((escapeText) => {
        // INFO - AM - 23/01/2023 - if at end of text then not possible to have it
        if (
          firstLetterAfterSeparationCharacterIndex + escapeText.length >
          text.length + 1
        ) {
          return;
        }
        let word = text.substr(
          firstLetterAfterSeparationCharacterIndex,
          firstLetterAfterSeparationCharacterIndex + escapeText.length
        );
        if (word.startsWith(escapeText)) {
          escapeWord = escapeText;
        }
      });

      if (escapeWord) {
        firstLetterAfterSeparationCharacterIndex =
          firstLetterAfterSeparationCharacterIndex + escapeWord.length;
      }

      // // INFO - AM - 06/04/2022 - If the first letter is one of the ignored one and text not finished we will use the next char.
      // // It is used for example for tag: #@colors
      // if (
      //   this.escapeFirstLetters.includes(firstLetterOfCurrentCaretWord) &&
      //   text.length > firstLetterAfterSeparationCharacterIndex
      // ) {
      //   firstLetterAfterSeparationCharacterIndex = firstLetterAfterSeparationCharacterIndex + escapeWord.length;
      // }

      firstLetterOfCurrentCaretWord =
        text[firstLetterAfterSeparationCharacterIndex];

      if (
        Object.keys(this.itemsByTags).includes(firstLetterOfCurrentCaretWord)
      ) {
        this.lastMatchingTag = firstLetterOfCurrentCaretWord;
        // INFO - AM - 06/04/2022 - Next line: take the text from the char tag until the end. Then we split by space and line break and limit to 1 to take only the first word
        this.lastMatchingWord = text
          .substring(firstLetterAfterSeparationCharacterIndex + 1, text.length)
          .split(/\s|\n/, 1)[0];
        this.$emit("needSuggest", {
          key: this.lastMatchingTag,
          text: this.lastMatchingWord,
        });

        const { x, y } = this.getCaretPos();
        this.menuProps["position-x"] = x;
        this.menuProps["position-y"] = y;
        this.menuProps.value = true;
      } else {
        this.lastMatchingTag = "";
        this.lastMatchingWord = "";
        this.menuProps.value = false;
      }

      this.$emit("input", this.processValue(text, false));
    },
  },
};
</script>
