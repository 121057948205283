<template>
  <div>
    <div v-for="item in items" :key="item.routeName">
      <VListItem
        v-if="!item.childrens"
        class="d-flex justify-start"
        :class="item.routeName === $route.name ? 'v-list-item--active' : ''"
        :ripple="false"
        :exact="item.exact"
        link
        :style="listItemStyle(item)"
        @click="$emit('click', item)"
      >
        <VListItemIcon class="mr-2">
          <VIcon :color="getItemColor(item)" size="28">
            {{ item.icon }}
          </VIcon>
        </VListItemIcon>
        <VListItemContent>
          <VListItemTitle
            :style="{ color: getItemColor(item) }"
            class="title-font"
          >
            {{ item.textLabel }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListGroup
        v-else
        :value="false"
        :style="listItemStyle(item)"
        :class="item.backgroundColor ? 'white-append-icon' : undefined"
      >
        <template #activator>
          <VListItemIcon class="mr-2">
            <VIcon :color="getItemColor(item)" size="28">
              {{ item.icon }}
            </VIcon>
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle
              :style="{ color: getItemColor(item) }"
              class="title-font"
            >
              {{ item.textLabel }}
            </VListItemTitle>
          </VListItemContent>
        </template>

        <VListItem
          v-for="subItem in item.childrens"
          :key="subItem.routeName"
          class="d-flex justify-start pl-8"
          :class="item.routeName === $route.name ? 'v-list-item--active' : ''"
          :exact="subItem.exact"
          :ripple="false"
          link
          @click="$emit('click', subItem)"
        >
          <VListItemIcon class="mr-2">
            <VIcon :color="getItemColor(subItem)" size="28">
              {{ subItem.icon }}
            </VIcon>
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle
              :style="{ color: getItemColor(item) }"
              class="title-font"
            >
              {{ subItem.textLabel }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </VListGroup>
    </div>
  </div>
</template>

<script>
export default {
  name: "DresskareNavListItems",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getItemColor(item) {
      return item.color ?? this.$root.primaryColor;
    },
    getBackgroundItemColor(item) {
      return item.backgroundColor ?? "white";
    },
    listItemStyle(item) {
      return { "background-color": this.getBackgroundItemColor(item) };
    },
  },
};
</script>

<style>

</style>
