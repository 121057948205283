import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import i18n from "@/setup/i18n";
import store from "@/store";
import { create } from "@bufbuild/protobuf";
import {
  ProductAnnouncementController,
  ProductAnnouncementRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
import { Model } from "@vuex-orm/core";
const announcementProductClient = useDresskareGrpcClient(
  ProductAnnouncementController
);

export default class ProductAnnouncement extends Model {
  static entity = "productannouncement";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      product: this.attr(null),
      announcement: this.attr(null),
      title: this.string(""),
      description: this.string(""),
    };
  }

  static getProductsAnnouncements = async (filters) => {
    try {
      const response = await announcementProductClient.list({
        Filters: filters,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await announcementProductClient.create(
        create(ProductAnnouncementRequestSchema, form)
      );

      this.insertOrUpdate({ data: response });
      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successUpdate")
      );
      return response;
    } catch (error) {
      console.error("error:", error);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static getAnnouncementProductByUuid = async (announcementUuid) => {
    const rateSlideFromStore = this.find(announcementUuid);

    if (rateSlideFromStore) {
      return rateSlideFromStore;
    } else {
      try {
        const retrievePromise = announcementProductClient.retrieve(
          { uuid: announcementUuid },
          {}
        );
        const response = await retrievePromise;

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        store.dispatch("notifications/showErrorNotification", i18n.t("error"));
        return null;
      }
    }
  };

  static updateInDb = async (form) => {
    const response = await announcementProductClient.update(
      create(ProductAnnouncementRequestSchema, form),
      {}
    );
    store.dispatch(
      "notifications/showSuccessNotification",
      i18n.t("successUpdate")
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (announcementUuid) => {
    try {
      await announcementProductClient.destroy({ uuid: announcementUuid }, {});
      this.delete(announcementUuid);
      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successDelete")
      );
    } catch (error) {
      console.error("error:", error);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
    }
  };
}
